// the Uniswap Default token list lives here


export const DEFAULT_TOKEN_LIST_URL = window.location.origin+'/tokenlist.json?data=20250227'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
  //'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
  // 'https://swap.hebe.cc/hebeswap.tokenlist.json'
  // <--tokens.uniswap.eth-->
]
